export const menuItemsENTITYDGEFC = [{
        id: 7,
        label: "Menu",
        isTitle: true,
    },
    {
        id: 9,
        label: "Directions",
        link: "/entity/directions",
        icon: "bx-calendar",
    },
    {
        id: 10,
        label: "IF & CTAF",
        icon: "bx-chat",
        link: "/entity/if-ctafs",
    },
    {
        id: 10,
        label: "Forêts",
        icon: "bx-calendar",
        link: "/entity/forests",
    },
    {
        id: 10,
        label: "Conventions",
        icon: "bx-filter-alt",
        link: "/entity/conventions",
    },
    {
        id: 12,
        label: "Informations",
        icon: "bx-mouse-alt",
        subItems: [{
            id: 13,
            label: "PTA",
            link: "/entity/informations/pta",
            parentId: 12
        }, {
            id: 13,
            label: "Rapport",
            link: "/entity/informations/report",
            parentId: 12
        }, {
            id: 13,
            label: "Plan d'aménagement",
            link: "/entity/informations/development_plan",
            parentId: 12
        }, {
            id: 13,
            label: "Photos",
            link: "/entity/informations/photo",
            parentId: 12
        }, {
            id: 13,
            label: "Cartes",
            link: "/entity/informations/card",
            parentId: 12
        }, ]
    },
    {
        id: 12,
        label: "Définition",
        icon: "bx-mouse-alt",
        subItems: [{
            id: 13,
            label: "Utilisateurs",
            link: "/entity/users",
            parentId: 12
        }, ]
    },
];


export const menuItemsENTITY = [{
        id: 7,
        label: "Menu",
        isTitle: true,
    },
    {
        id: 10,
        label: "IF & CTAF",
        icon: "bx-chat",
        link: "/entity/if-ctafs",
    },
    {
        id: 10,
        label: "Forêts",
        icon: "bx-calendar",
        link: "/entity/forests",
    },
    {
        id: 10,
        label: "Conventions",
        icon: "bx-filter-alt",
        link: "/entity/conventions",
    },
    {
        id: 12,
        label: "Informations",
        icon: "bx-mouse-alt",
        subItems: [{
            id: 13,
            label: "PTA",
            link: "/entity/informations/pta",
            parentId: 12
        }, {
            id: 13,
            label: "Rapport",
            link: "/entity/informations/report",
            parentId: 12
        }, {
            id: 13,
            label: "Plan d'aménagement",
            link: "/entity/informations/development_plan",
            parentId: 12
        }, {
            id: 13,
            label: "Photos",
            link: "/entity/informations/photo",
            parentId: 12
        }, {
            id: 13,
            label: "Cartes",
            link: "/entity/informations/card",
            parentId: 12
        }, ]
    },
    {
        id: 12,
        label: "Définition",
        icon: "bx-mouse-alt",
        subItems: [{
            id: 13,
            label: "Utilisateurs",
            link: "/entity/users",
            parentId: 12
        }, ]
    },
];


export const menuItemsCTAF = [{
        id: 7,
        label: "Menu",
        isTitle: true,
    },
    {
        id: 10,
        label: "Forêts",
        icon: "bx-calendar",
        link: "/ctaf/forests",
    },
    {
        id: 10,
        label: "Conventions",
        icon: "bx-filter-alt",
        link: "/ctaf/conventions",
    },
    {
        id: 12,
        label: "Informations",
        icon: "bx-mouse-alt",
        subItems: [{
            id: 13,
            label: "PTA",
            link: "/ctaf/informations/pta",
            parentId: 12
        }, {
            id: 13,
            label: "Rapport",
            link: "/ctaf/informations/report",
            parentId: 12
        }, {
            id: 13,
            label: "Plan d'aménagement",
            link: "/ctaf/informations/development_plan",
            parentId: 12
        }, {
            id: 13,
            label: "Photos",
            link: "/ctaf/informations/photo",
            parentId: 12
        }, {
            id: 13,
            label: "Cartes",
            link: "/ctaf/informations/card",
            parentId: 12
        }, ]
    },
];

export const menuItemsIF = [{
        id: 7,
        label: "Menu",
        isTitle: true,
    },
    {
        id: 11,
        label: "CTAF",
        link: "/if/ctafs",
        icon: "bx-file",
    },
    {
        id: 10,
        label: "Forêts",
        icon: "bx-calendar",
        link: "/if/forests",
    },
    {
        id: 10,
        label: "Conventions",
        icon: "bx-filter-alt",
        link: "/if/conventions",
    },
    {
        id: 12,
        label: "Informations",
        icon: "bx-mouse-alt",
        subItems: [{
            id: 13,
            label: "PTA",
            link: "/if/informations/pta",
            parentId: 12
        }, {
            id: 13,
            label: "Rapport",
            link: "/if/informations/report",
            parentId: 12
        }, {
            id: 13,
            label: "Plan d'aménagement",
            link: "/if/informations/development_plan",
            parentId: 12
        }, {
            id: 13,
            label: "Photos",
            link: "/if/informations/photo",
            parentId: 12
        }, {
            id: 13,
            label: "Cartes",
            link: "/if/informations/card",
            parentId: 12
        }, ]
    },
];