export const menuItems = [{
        id: 1,
        label: 'menuitems.dashboards.text',
        icon: 'bx-home-circle',
        subItems: [{
                id: 2,
                label: 'menuitems.dashboards.list.default',
                link: '/',
                parentId: 1
            },
            {
                id: 3,
                label: 'menuitems.dashboards.list.saas',
                link: '#',
                parentId: 1
            },
            {
                id: 4,
                label: 'menuitems.dashboards.list.crypto',
                link: '#',
                parentId: 1
            },
            {
                id: 5,
                label: 'menuitems.dashboards.list.blog',
                link: '#',
                parentId: 1
            },
        ]
    },

];